// Pagination styles =====================

.pagination {
  align-items: center;
  display: flex;
  gap: var(--spacing__small);
  list-style: none;
  margin: var(--spacing__xlarge) 0 0;
  justify-content: center;
  padding: 0;

  &--loading {
    display: none;
  }
}

.pagination__link {
  align-items: center;
  border: 1px solid var(--color__line-light);
  border-radius: 50%;
  color: rgb(var(--color__primary));
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;

  &:hover {
    background-color: var(--color__light-background);
    color: var(--color__label);
  }

  &--active {
    background-color: rgb(var(--color__primary));
    border-color: rgb(var(--color__primary));
    color: var(--color__white);

    &:hover {
      background-color: rgb(var(--color__primary));
      border-color: rgb(var(--color__primary));
      color: var(--color__white);
    }
  }

  &--next,
  &--prev {
    cursor: pointer;
    display: block;
    padding: var(--spacing);

    &:hover {
      color: var(--color__label);
    }
  }

  &--disabled {
    color: var(--color__label);
    cursor: initial;
    opacity: 0.3;
  }
}

.loader--centered {
  margin: 5px auto 5px auto;
  width: 100%;
  text-align: center;
}
