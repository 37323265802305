// REM Sizings based on 16px body size

:root {
  --border__radius: 12px;
  --border__radius--mid: calc(var(--border__radius)/2);
  --border__radius--small: calc(var(--border__radius)/3);
  --color__white: #ffffff;
  --color__white-rgb: 250, 250, 250;
  --color__black: #0D0D0D;
  --color__black-rgb: 13, 13, 13;
  --color__mixlr-red: #ed1c24;
  --color__mixlr-red-rgb: 237, 28, 36;
  --color__mixlr-red-filter: #{brightness(0) saturate(100%) invert(13%) sepia(89%) saturate(5499%) hue-rotate(352deg) brightness(99%) contrast(88%)};
  --color__mixlr-red-80: #f14950;
  --color__green: #00b274;
  --color__green--light: #CCF0E3;
  --color__orange: #ff5400;
  --color__orange--light: #FFDDCC;
  --color__blue: #47ADD8;
  --color__blue--light: #DAEFF7;
  --color__red: #F03E45;
  --color__red--light: #FCD8DA;
  --color__black-90: #191919;
  --color__black-80: #333333;
  --color__black-70: #262626;
  --color__black-50: #7F7F7F;
  --color__black-40: #999999;
  --color__black-40-filter: #{brightness(0) saturate(100%) invert(72%) sepia(1%) saturate(831%) hue-rotate(329deg) brightness(87%) contrast(78%)};
  --color__line: #cccccc;
  --color__line-light: #e1e1e1;
  --color__border: #cccccc;
  --color__background: var(--color__white);
  --color__background-rgb: var(--color__white-rgb);
  --color__light-background: #f3f3f3;
  --color__line-android: #7E7F80;
  --color__dark-android: #5F6363;
  --color__light-background-android: #D4D8D8;
  --color__mid-android: #9D9E9F;
  --color__facebook: #4267B2;
  --color__label: var(--color__black);
  --font__family: 'Montserrat', 'Avenir', -apple-system, 'Roboto', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font__size-body: 1.6rem;
  --font__size-mega: 4.2rem;
  --font__size-h1: 3rem;
  --font__size-h2: 2.8rem;
  --font__size-h3: 2.4rem;
  --font__size-h4: 2rem;
  --font__size-h5: 2rem;
  --font__size-large: 1.8rem;
  --font__size-small: 1.4rem;
  --font__size-mini: 1.2rem;
  --font__size-tiny: 1rem;
  --input-height: 4.8rem;
  --layout__margin: 1.6rem;
  --layout__padding: 1.6rem;
  --spacing: 0.8rem;
  --spacing__small: calc(var(--spacing) / 2);
  --spacing__large: calc(var(--spacing) * 2);
  --spacing__xlarge: calc(var(--spacing) * 3);
  --spacing__xxlarge: calc(var(--spacing) * 4);
  --alert__animation: var(--spacing__xlarge);

  // Vendor
  --swiper-pagination-color: var(--color__light-background);
  --swiper-theme-color: var(--color__mixlr-red);

  // Safe areas
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);

  // App specfic
  --audio-panel-height: calc(90px + env(safe-area-inset-bottom));
}

.dark-mode {
  --color__label: var(--color__white);
  --color__background: var(--color__black);
  --color__background-rgb: var(--color__black-rgb);
  --color__light-background: var(--color__black-80);
  --color__line-light: #{rgba(250,250,250, .2)};
  --color__line: var(--color__black-50);
}

@media (prefers-color-scheme: dark) {
  .ios,
  .standalone {
    --color__label: var(--color__white);
    --color__background: var(--color__black);
    --color__background-rgb: var(--color__black-rgb);
    --color__light-background: var(--color__black-80);
    --color__line-light: #{rgba(250,250,250, .2)};
    --color__line: var(--color__black-50);
  }
}

// Support iOS dynamic font sizing
// -apple-system-body is 17px so need to account for sizing down to 16px

.creator-da {
  --alert__animation: 44px;
}

.ios {
  font: -apple-system-body;
  supported-color-schemes: light dark;

  --font__size-body: 0.9412rem;
  --font__size-mega: 3.2rem;
  --font__size-h1: 2rem;
  --font__size-h2: 1.647rem;
  --font__size-h3: 1.412rem;
  --font__size-h4: 1.176rem;
  --font__size-h5: 1.176rem;
  --font__size-large: 1.176rem;
  --font__size-small: 0.8235rem;
  --font__size-mini: 0.7059rem;
  --input-height: 48px;
  --layout__margin: 16px;
  --layout__padding: 16px;
  --spacing: 8px;
}
