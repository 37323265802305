@import "scss/mixins";

// Chat =======================================

.chat {
  border-radius: var(--border__radius);
  color: var(--color__label);
  height: 100%;
  width: 100%;
}

.chat__header {
  border-bottom: 1px solid var(--color__line-light);
  align-items: center;
  display: flex;
  font-weight: 700;
  gap: var(--spacing);
  height: 42px;
  justify-content: space-between;
  padding: 0 var(--spacing__small);
  position: relative;

  @include gap-fallback(var(--spacing));

  &:first-child,
  &:last-child {
    flex: 0;
  }

  button {
    color: var(--color__label);
    
    &:hover {
      opacity: 0.7;
    }
  }
}

.chat__scroll {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  position: relative;
  width: 100%;
}

.chat__scroll-button {
  background-color: rgba(var(--color__primary), 0.9);
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  transition: all .1s ease-in-out;
  z-index: 10;

  &:hover {
    background-color: rgba(var(--color__primary), 1);
  }

  button {
    color: var(--color__white);
    font-size: var(--font__size-mini);
    font-weight: 700;
    justify-content: center;
    width: 100%;

    &:hover {
      color: var(--color__white);
    }

    :global(.light-contrast) & {
      color: var(--color__black);

      &:hover {
        color: var(--color__black);
      }
    }
  }
}

.chat__list {
  flex: 0 0 auto;
  list-style: none;
  margin: auto 0 0;
  padding: var(--spacing) var(--spacing__large);
  -webkit-transform: translate3d(0, 0, 0);
}

// Chat Input ==================================

.chat__prompt {
  border-radius: 0 0 var(--border__radius) var(--border__radius);
  border-top: 1px solid var(--color__line-light);
  min-height: 42px;
  padding: var(--spacing__large);
  text-align: center;

  > button,
  > a {
    justify-content: center;
    white-space: wrap;
  }
  
  .chat--crowd-visible & {
    @media screen and (min-width: 740px) {
      border-radius: 0 0 var(--border__radius) 0;
    }
  }
}

.chat__input {
  align-items: center;
  border-radius: 0 0 var(--border__radius) var(--border__radius);
  border-top: 1px solid var(--color__line-light);
  color: var(--color__label);
  padding: 0 var(--spacing__large);
  position: relative;

  .chat--crowd-visible & {
    @media screen and (min-width: 740px) {
      border-radius: 0 0 var(--border__radius) 0;
    }
  }
}

.chat__form {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;

  > * + * {
    margin-left: var(--spacing);
  }
  
  button {
    color: var(--color__label) !important;

    &:hover {
      opacity: 0.7 !important;
    }
  }
}

.chat__submit {
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: 0;
  color: var(--color__label);
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: var(--font__size-small);
  font-weight: 800;
  justify-content: center;
  padding: 0;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.chat__textarea {
  background-color: transparent;
  border: 0;
  caret-color: rgb(var(--color__primary));
  color: var(--color__label);
  font-family: inherit;
  font-size: var(--font__size-small);
  font-weight: 500;
  height: auto;
  min-height: 45px;
  max-height: 200px;
  overflow: auto;
  padding: 12px var(--spacing__large);
  padding-left: 0;
  transition: all .2s ease-in-out;
  word-break: break-word;
  width: 100%;

  &:focus {
    outline: none;
  }

  img {
    border-radius: 6px;
    max-width: 50%;
  }

  &:empty:before {
    content: attr(placeholder);
    color: var(--color__black-50);
    font-size: var(--font__size-small);
    pointer-events: none;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.chat__textarea::-webkit-input-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat__textarea::-moz-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat__textarea:-ms-input-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


// Chat Comments ===============================

.comment-wrapper {
  margin: 0;
  padding: var(--spacing__small) 0;
}

.comment {
  display: flex;
  font-size: var(--font__size-small);
  opacity: 1;
  letter-spacing: -.2px;
  margin: 0;
  transition: all 0.3s ease-in-out;
  visibility: visible;

  &:hover {
    :global(body:not(.mobile):not(.tablet)) & {
      .comment__actions {
        opacity: 1;
      }
    }
  }
}

.comment__profile {
  cursor: pointer;
  flex: none;
  margin-right: var(--spacing);
  position: relative;

  .comment--broadcaster & {
    &:before {
      align-items: center;
      border: 1px solid var(--color__background);
      background-color: rgb(var(--color__primary));
      border-radius: 50%;
      display: flex;
      color: var(--color__white);
      content: "★";
      height: 12px;
      font-size: 6px;
      left: -2px;
      line-height: 6px;
      justify-content: center;
      margin-right: var(--spacing__small);
      position: absolute;
      top: -2px;
      width: 12px;
    }
  }
}

.comment__content {
  flex: 1;
  margin-top: var(--spacing__small);
  overflow: hidden;
}

.comment__meta {
  align-items: baseline;
  color: var(--color__black-40);
  display: flex;
  gap: var(--spacing__large);

  @include gap-fallback(var(--spacing__large));
}

.comment__time {
  font-size: var(--font__size-mini);
  text-align: right;
}

.comment__text {
  display: flex;
  flex: 1;

  img {
    border-radius: 6px;
    max-width: 50%;
  }
}

.comment__text__block {
  flex: 1;
  margin: 0;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  > img {
    border-radius: 6px;
    max-width: 50%;
  }
}

.comment__image {
  display: flex;
}

.comment__username {
  color: var(--color__black-50);
  flex: 1;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;

  .comment--broadcaster & {
    font-weight: 600;
  }

  &:hover {
    color: rgb(var(--color__primary));
  }
}

.comment-wrapper--heart {
  margin: -2px var(--spacing) 0 0;
  padding: 0;
}

.comment--heart__icon {
  align-items: center;
  display: flex;
  flex: none;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.comment--heart {
  color: var(--color__black-50);
  display: flex;
  font-size: var(--font__size-small);
  gap: var(--spacing);

  @include gap-fallback(var(--spacing));

  svg {
    display: block;
    fill: var(--color__mixlr-red);
  }

   span {
      margin-top: var(--spacing__small);
   }
}

.chat-profile {
  text-align: center;
  width: 100%;
}

.chat-profile__username {
  margin: var(--spacing) 0;
}

.chat-profile__close {
  position: absolute;
  right: var(--spacing);
  top: var(--spacing);
}

.chat-profile__actions {
  list-style: none;
  display: flex;
  gap: var(--spacing);
  justify-content: center;
  margin: var(--spacing__large) 0 var(--spacing__large);
  padding: 0;

  @include gap-fallback(var(--spacing));
}

// Empty chat

.chat__empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 var(--spacing__large);
  text-align: center;
  white-space: pre-line;

  > svg {
    fill: rgb(var(--color__primary));
    display: block;
    margin-bottom: var(--spacing__large);
  }

  p:last-of-type {
    margin: 0;
  }

  @media screen and (orientation:landscape) and (max-height: 500px) {
    svg {
      display: none;
    }
  }
}

// Comment actions ========================

.comment__actions {
  color: var(--color__black-40);
  flex: none;
  margin-left: var(--spacing__large);
  position: relative;
  transition: opacity .2s ease-in-out;

  :global(body:not(.mobile):not(.tablet)) & {
    opacity: 0;
  }

  button {
    height: 12px;
    padding: 0;
    width: 12px;
  }

  svg {
    stroke: none;
  }
}

.comment__actions button {
  color: var(--color__black-40);
}
