// Mixlr Header =======================================

.mixlr-footer {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  flex: 0;
  justify-content: center;
  padding: var(--spacing__large);
  position: relative;
  width: 100%;
}

.mixlr-footer__logo {
  height: auto;
  margin-left: 1rem;
  width: 6rem;

  svg {
    fill: var(--color__label);
    height: 100%;
    width: 100%;
  }
}